import translations from './translations.json'

export class Translate {
	constructor(path, lang) {
		this.path = path
		this.lang = lang
	}

	getWord() {
		return (word) => {
			if (translations[this.path] &&
				translations[this.path][word] &&
				translations[this.path][word][this.lang]
			) {
				return translations[this.path][word][this.lang];
			}

			return word;
		}
	}
}