import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { registerWebmaster, loginWebmaster, resetStatus } from "redux/slices/auth/authSlice";
import Cookies from "js-cookie";

// Components
import {Input, CustomSelect} from "components/general";
import Button from "components/general/Button/Button";
import { Dropdown } from "react-bootstrap";

// Utils
import { Translate } from "utils/translations/Translate";
import ReCAPTCHA from "react-google-recaptcha";

// Styles
import styles from "./RegLogModal.module.scss";
import {setCurrentPage, setRegion} from "../../../redux/slices/offers/offersSlice";


const SITE_KEY = process.env.REACT_APP_SITE_KEY
const AUTH_HOST = process.env.REACT_APP_AUTH_HOST

const messengers = {
	telegram: 'Contact',
	skype: 'Skype ID',
	whatsapp: 'Phone number with country code'
}

const Registration = ({ showRegLogModal }) => {
	const dispatch = useDispatch()
	const {throttlingLevel, status, message, loading} = useSelector(state => state.auth)
	const { lang } = useSelector(state => state.translations)
	const [messenger, setMessenger] = useState(messengers["telegram"])

	// change fields depend on messenger
	const handleChange = (e) => {
		setMessenger(messengers[e.target.value.toLowerCase()])
	}

	// initialize translate class
	const translation = new Translate('public/auth', lang)
	const t = translation.getWord()

	// was captcha validated or not
	const [captcha, setCaptcha] = useState('')

	const { register, formState: { errors }, handleSubmit, watch} = useForm()

	function handleOnChange(value) {
		setCaptcha(value)
	}
//Registartion
const onSubmit = async (data) => {
	if (!captcha) return;
	const formData = new FormData();
	let contactType = data.contact_type;
	if (contactType === "WhatsApp") {
	  contactType = "whatsup";
	}
	formData.append("email", data.email);
	formData.append("password", data.password);
	if (data.contact) {
	  formData.append('contact_type', contactType.toLowerCase());
	  formData.append('contact', data.contact);
	}
	
	formData.append("gc", captcha);
	formData.append("lang", lang);
  
	const ga = Cookies.get('_ga');
	const rid = Cookies.get('rid');
  
	if (ga) formData.append("ga_client_id", ga);
	if (rid) formData.append("referral_id", rid);
  
	try {
		const registerResponse = await dispatch(registerWebmaster(formData));
	  
		if (registerResponse.payload.status === 'success') {
		  const loginData = {
			username: formData.get('email'),
			password: formData.get('password')
		  };
	  
		  const loginResponse = await dispatch(loginWebmaster(JSON.stringify(loginData)));
		  
		  const token = loginResponse.payload.data.token;
		  console.log('token', token);
		  if (token) {
			const adminPanelUrl = '/login.html';
			const loginUrl = `${adminPanelUrl}?appToken=${token}`;
			localStorage.removeItem('token');
			localStorage.removeItem('refresh');
			window.location.href = loginUrl;
		  }
		} else {
			console.log(registerResponse.payload.message);
			console.log(registerResponse.payload.status);	
			console.log(registerResponse.payload.data);
		}
	  } catch (error) {
		console.log(error);
	  }
	}


	

	useEffect(() => {
		// reset status when leave current component
		return () => {
			dispatch(resetStatus())
		}
	}, [dispatch])

	const onRegionSelected = (reg) => {
		dispatch(setCurrentPage(1))
		dispatch(setRegion(reg))
	}

	return (
		<div className={styles.modal__wrapper}>
			<h3 className={styles.modal__title}>{t('регистрация вебмастера')}</h3>
			<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.inputs__wrapper}>

					<Input
						name="email"
						placeholder='Email'
						register={register}
						errors={errors}
						required={{ required: true, pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i }}
					/>

					<Input
						name="password"
						type='password'
						placeholder={t('Пароль (минимум 7 символов)')}
						register={register}
						errors={errors}
						required={{ required: true, minLength: 7 }}
					/>

					<Input
						name="repeatPassword"
						type='password'
						placeholder={t('Повторите пароль')}
						register={register}
						errors={errors}
						required={{
							required: true,
							minLength: 7,
							validate: (val) => {
								if (watch('password') !== val) {
									return 'Your passwords do not match';
								}
							}
						}}
					/>

					<CustomSelect
						register={register}
						name="contact_type"
						className='select select__reg optional__input'
						handleChange={handleChange}
						classNamePrefix="select-reg" 
						options={["Telegram", "Skype", "WhatsApp"]}
						// required={{ required: false }}
					/>

					<Input
						name="contact"
						placeholder={messenger}
						register={register}
						errors={errors}
						// required={{ required: false }}
					/>


				</div>
				{status === 'error' && (
					<p className='error text-center mb-3'>{message}</p>
				)}


				<div className={styles.form__footer}>

						<ReCAPTCHA
						sitekey={SITE_KEY}
						onChange={handleOnChange}
						/>



					<Button className={`outline-green ${loading ? 'disabled' : ''}`}>{t('Зарегистрироваться')}</Button>
				</div>
			</form>
			<p className={"rules__form"}>
				{t('При нажатии "Зарегистрироваться", вы подтверждаете, что ознакомились и согласны с ')} <Link to='/rules.html' target='_blank' rel='noopener noreferrer'>{t('Условиями пользовательского соглашения')}</Link>
			</p>

			<div className="google-autorize">
				<Link to={AUTH_HOST+"v1/google/auth"} className="google-btn">{t('Войти через Google')}</Link>
			</div>

			<div className={styles.modal__text}><p>{t('Уже есть аккаунт?')}</p><span
				onClick={() => showRegLogModal('login')}>{t('Войти')}</span></div>

		</div>
	);
};

export default Registration;