import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { loginWebmaster, resetStatus } from "redux/slices/auth/authSlice";
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import { Input } from "components/general";
import Button from "components/general/Button/Button";

// Utils
import { Translate } from "utils/translations/Translate";
import ReCAPTCHA from "react-google-recaptcha";

// Styles
import styles from "./RegLogModal.module.scss";

const SITE_KEY = process.env.REACT_APP_SITE_KEY;

const Login = ({ showRegLogModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { throttlingLevel, status, message, loading } = useSelector(state => state.auth);
  const { lang } = useSelector(state => state.translations);

  // initialize translate class
  const translation = new Translate('public/auth', lang);
  const t = translation.getWord();

  // was captcha validated or not
  const [captcha, setCaptcha] = useState('');

  const { register, formState: { errors }, handleSubmit } = useForm();

  function handleOnChange(value) {
    setCaptcha(value);
  }

  const onSubmit = (data) => {
    if (throttlingLevel !== 'low' && !captcha) return;
    const raw = {
      username: data.email,
      password: data.password,
    };
    if (throttlingLevel !== 'low') {
      raw.captcha = captcha;
    }
    dispatch(loginWebmaster(JSON.stringify(raw)))
    .then(() => {
      const token = localStorage.getItem('token');
      if (token) {
        const adminPanelUrl = '/login.html';
        const loginUrl = `${adminPanelUrl}?appToken=${token}`;
        localStorage.removeItem('token');
        localStorage.removeItem('refresh');
        window.location.href = loginUrl;
      }
    });
  };

  useEffect(() => {
    // reset status when leave current component
    return () => {
      dispatch(resetStatus());
    };
  }, [dispatch]);

  // useEffect(() => {
  //   if (status === 'success') {
  //     const token = localStorage.getItem('token');
  //     if (token) {
  //       const adminPanelUrl = '/login.html';
  //       const loginUrl = `${adminPanelUrl}?appToken=${token}`;
  //       localStorage.removeItem('token');
  //       localStorage.removeItem('refresh');
  //       window.location.href = loginUrl;
  //     }
  //   }
  // }, [status]);

  return (
    <div className={`${styles.modal__wrapper} ${styles.login}`}>
      <h3 className={styles.modal__title}>{t('вход')}</h3>
      <div className={styles.modal__text}>
        <p>{t('Еще не зарегистрированы?')}</p>
        <span onClick={() => showRegLogModal('registration')}>{t('Регистрация')}</span>
      </div>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputs__wrapper}>
          <Input
            name="email"
            placeholder={t('Ваш логин')}
            register={register}
            errors={errors}
            required={{ required: true }}
          />

          <Input
            name="password"
            type='password'
            placeholder={t('Пароль')}
            register={register}
            errors={errors}
            required={{ required: true }}
          />

          <Input
            name="rememberMe"
            type='checkbox'
            className='checkbox'
            label={<p className={"checkbox__label"}>{t('Запомнить меня')}</p>}
            register={register}
            errors={errors}
          />

          <span onClick={() => showRegLogModal('forgotPassword')} className={styles.forgot__password}>{t('Забыли пароль?')}</span>
        </div>

        {status === 'error' && (
          <p className='error my-3'>{message}</p>
        )}

        <div className={styles.form__footer}>
          {/* Don't show captcha if throttlingLevel is low */}
          {throttlingLevel !== 'low' && (
            <ReCAPTCHA
              sitekey={SITE_KEY}
              onChange={handleOnChange}
            />
          )}

          <Button className={`outline-green ${loading ? 'disabled' : ''}`}>{t('Войти')}</Button>
        </div>
      </form>
    </div>
  );
};

export default Login;