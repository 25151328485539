import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FooterFactory, HeaderFactory } from "./components/public";
import RegLogModal from "./components/public/RegLogModal/RegLogModal";
import { ModalProvider } from "./utils/customHooks/ModalContext";
import { checkIsAuth, getThrottlingLevel } from "./redux/slices/auth/authSlice";
import { setLang }  from './redux/slices/translations/translationSlice' // Імпортуємо action для зміни мови
import privateRoutes from "./config/routing/privateRoutes";
import publicRoutes from "./config/routing/publicRoutes";

import './scss/app.scss'

const getDefaultLanguage = (path) => {
  switch (path) {
    case '/co':
      return 'es';
    case '/kz':
      return 'ru';
    case '/ua':
      return 'ua';
    default:
      return 'en';
  }
};

function App() {
  const dispatch = useDispatch();
  const isAuth = useSelector(checkIsAuth);
  const location = useLocation();

  const landingPages = ['/ph', '/vn', '/us', '/ro', '/es', '/mx', '/pl', '/ng', '/co', '/cz', '/in', '/kz', '/ke', '/za', '/lk', '/ua'];
  const isLandingPage = () => landingPages.includes(location.pathname);

  const routes = isAuth ? privateRoutes : publicRoutes;

  useEffect(() => {
    if (!isAuth) {
      dispatch(getThrottlingLevel());
    }
  }, [dispatch, isAuth]);

  useEffect(() => {
    if (isAuth) {
      const token = localStorage.getItem('token');
      if (token) {
        // Replace 'adminPanelUrl' with the actual URL of your admin panel
        const adminPanelUrl = '/login.html';
        const loginUrl = `${adminPanelUrl}?appToken=${token}`;
        localStorage.removeItem('token');
        localStorage.removeItem('refresh');
        window.location.href = loginUrl;
      } else {
        console.error('Token not found in local storage');
      }
    }
  }, [isAuth]);

  useEffect(() => {
    const storedLang = localStorage.getItem('lang');
    const newLang = getDefaultLanguage(location.pathname);
    if (!storedLang || (landingPages.includes(location.pathname) && storedLang !== newLang)) {
      dispatch(setLang(newLang));
    }
  }, [location, dispatch, landingPages]);

  return (
    <ModalProvider>
      {/* Different Header and Footer for public and private routes */}
      <HeaderFactory isAuth={isAuth} isLandingPage={isLandingPage()} />
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
      <FooterFactory isAuth={isAuth} isLandingPage={isLandingPage()} />
      {/* If user is auth don't show login modal */}
      {!isAuth && <RegLogModal />}
    </ModalProvider>
  )
}

export default App;
