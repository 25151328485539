import React from 'react';
import styles from './IndividualAdvantages.module.scss'

import indAdv from "assets/images/public/individual_advantages/indAdv.png";

const IndividualAdvantages = ({ data }) => {
	return (
		<section className={styles.indAdv}>
			<div className={styles.indAdv__wrapper}>
				<h1 className={`${styles.main__title}`}>{data?.title}</h1>
				<h2 className={`${styles.main__subtitle}`}>
					{data?.subtitle}
				</h2>
				<div className={styles.indAdv__img}>
					<img src={indAdv} alt="indAdv" />
				</div>
				<ul className={styles.indAdv__advantages}>

					{data.list.map((v, i) => (
						<li key={i}>
							<span>0{i + 1}</span>
							<h3>{v.header}</h3>
							<p>{v.text}</p>
						</li>
					))}

				</ul>
			</div>
		</section>
	);
};

export default IndividualAdvantages;