import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setLang } from "redux/slices/translations/translationSlice";
import { NavLink } from "react-router-dom";

// Components
import { RegBlock } from "../index";
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

// Utils
import { useModalContext } from "utils/customHooks/ModalContext";
import { Translate } from "utils/translations/Translate";

// Styles
import classNames from "classnames";
import styles from './HeaderPublic.module.scss'
import logo from 'assets/images/general/logo-pdl.svg'

const languages = {
    en: 'ENG',
	ru: 'РУС',
	ua: 'УКР',
    fr: 'FRA',
    es: 'ESP'
}

function HeaderPublic() {
	const dispatch = useDispatch()
	const [scrolled, setScrolled] = useState(false)
	const [expanded, setExpanded] = useState(false)
	const [windowWidth, setWindowWidth] = useState(window.innerWidth)
	const { lang } = useSelector(state => state.translations)
	const { showRegLogModal } = useModalContext()

	// initialize translate class
	const translation = new Translate('headerFactory', lang)
	const t = translation.getWord()

	const handleLanguageClick = (lang) => {
		dispatch(setLang(lang))
	}

	useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("scroll", () =>
				setScrolled(window.scrollY > 2)
			);
		}
	}, []);

	useEffect(() => {
		if (expanded) {
			document.body.classList.add('no-scroll')
		} else {
			document.body.classList.remove('no-scroll')
		}
	}, [expanded])

	return (
		<header className={styles.header}>
			<Navbar fixed='top' expanded={expanded} expand="xl" className={classNames({ 'scrolled': scrolled })}>
				<div className='container d-flex w-100 justify-content-between align-items-center'>
					<NavLink to='/'><img src={logo} alt="logo-pdl" /></NavLink>
					<RegBlock showRegLogModal={showRegLogModal} display={'d-none d-lg-flex align-self-end d-xl-none'} />
					<Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")}
								   aria-controls="basic-navbar-nav">
						<div className={'header__burger'}>
							<span />
						</div>
					</Navbar.Toggle>
					<Navbar.Collapse>
						<div
							className={`wrapper d-flex justify-content-center justify-content-xl-between w-100 align-items-center`}>
							<div className={styles.menu}>
								<NavLink
									onClick={() => setExpanded(false)}
									className={({ isActive }) => classNames([styles.link], { [styles.active]: isActive })}
									to='/' end>{t('Вебмастерам')}</NavLink>
								<NavLink
									onClick={() => window.location.href = 'https://pdl-profit.com/advertiser.html'}
									className={({ isActive }) => classNames([styles.link])}
									to='/'>{t('Рекламодателям')}</NavLink>
								<NavLink
									onClick={() => setExpanded(false)}
									className={({ isActive }) => classNames([styles.link], { [styles.active]: isActive })}
									to='/our-offers.html'>{t('Офферы')}</NavLink>
							</div>
							<div className='d-flex align-items-center flex-column flex-xl-row'>
								<RegBlock showRegLogModal={showRegLogModal} display={'d-flex d-lg-none d-xl-flex'} />
								{windowWidth < 1200 ? (
									<div className={styles.lang_block}>
										{Object.keys(languages).map((l, i) => (
											<span key={i}
												className={classNames({
													[styles.active]: lang === l
												})}
												onClick={() => handleLanguageClick(l)}
											>
												{languages[l]}
											</span>
										))}
									</div>

								) : (
									<div className={styles.lang_block}>
										<NavDropdown title={languages[lang]} id="basic-nav-dropdown">
											{Object.keys(languages).map((l, i) => (
												<NavDropdown.Item key={i} onClick={() => handleLanguageClick(l)} active={lang === l}>{languages[l]}</NavDropdown.Item>
											))}
										</NavDropdown>
									</div>
								)}
									
							</div>

						</div>

					</Navbar.Collapse>

				</div>
			</Navbar>
		</header>


	);
}

export default HeaderPublic;