import { 
	MAIN_PATH,
} from "./path";
import { Loader } from "components/general";

const MAIN = {
	element: <Loader/>,
	path: MAIN_PATH,
};

const routes = [
	MAIN,
];

export default routes;