import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const HOST = process.env.REACT_APP_HOST

export const profitKeniaOffersApi = createApi({
	reducerPath: 'profitKeniaOffersApi',
	baseQuery: fetchBaseQuery({ baseUrl: HOST }),
	endpoints: (build) => ({
		getProfitKeniaOffers: build.query({
			query: (lang) => `v1/openstat/offers?lang=${lang}&region=KE&page=1&perPage=10&showOnMain=1`
		})
	})
})

export const { useGetProfitKeniaOffersQuery } = profitKeniaOffersApi