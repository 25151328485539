import { configureStore } from '@reduxjs/toolkit'
import { offersSlice } from "./slices/offers/offersSlice";
import { authSlice } from "./slices/auth/authSlice";
import { profitOffersApi } from "./slices/offers/profitOffersApi";
import { profitPhilippinesOffersApi } from "./slices/offers/profitPhilippinesOffersApi";
import { profitVietnamOffersApi } from "./slices/offers/profitVietnamOffersApi";
import { profitUnitedStatesOffersApi } from "./slices/offers/profitUnitedStatesOffersApi";
import { profitRomaniaOffersApi } from "./slices/offers/profitRomaniaOffersApi";
import { profitSpainOffersApi } from "./slices/offers/profitSpainOffersApi";
import { profitMexicoOffersApi } from "./slices/offers/profitMexicoOffersApi";
import { profitPolandOffersApi } from "./slices/offers/profitPolandOffersApi";
import { profitNigeriaOffersApi } from "./slices/offers/profitNigeriaOffersApi";
import { profitColumbiaOffersApi } from "./slices/offers/profitColumbiaOffersApi";
import { profitCzechiaOffersApi } from "./slices/offers/profitCzechiaOffersApi";
import { profitKazakhstanOffersApi } from "./slices/offers/profitKazakhstanOffersApi";
import { profitIndiaOffersApi } from "./slices/offers/profitIndiaOffersApi";
import { profitKeniaOffersApi } from "./slices/offers/profitKeniaOffersApi";
import { profitSouthAfricaOffersApi } from "./slices/offers/profitSouthAfricaOffersApi";
import { profitSriLankaOffersApi } from "./slices/offers/profitSriLankaOffersApi";
import { profitUkraineOffersApi } from "./slices/offers/profitUkraineOffersApi";
import { openStatOffersApi } from "./slices/offers/openStatOffersApi";
import { translationSlice } from "./slices/translations/translationSlice";
import { top10OffersApi } from "./slices/offers/top10Offers";

const rootReducer = {
	offers: offersSlice.reducer,
	auth: authSlice.reducer,
	translations: translationSlice.reducer,
	[profitOffersApi.reducerPath]: profitOffersApi.reducer,
	[profitPhilippinesOffersApi.reducerPath]: profitPhilippinesOffersApi.reducer,
	[profitVietnamOffersApi.reducerPath]: profitVietnamOffersApi.reducer,
	[profitUnitedStatesOffersApi.reducerPath]: profitUnitedStatesOffersApi.reducer,
	[profitRomaniaOffersApi.reducerPath]: profitRomaniaOffersApi.reducer,
	[profitSpainOffersApi.reducerPath]: profitSpainOffersApi.reducer,
	[profitMexicoOffersApi.reducerPath]: profitMexicoOffersApi.reducer,
	[profitPolandOffersApi.reducerPath]: profitPolandOffersApi.reducer,
	[profitNigeriaOffersApi.reducerPath]: profitNigeriaOffersApi.reducer,
	[profitColumbiaOffersApi.reducerPath]: profitColumbiaOffersApi.reducer,
	[profitCzechiaOffersApi.reducerPath]: profitCzechiaOffersApi.reducer,
	[profitKazakhstanOffersApi.reducerPath]: profitKazakhstanOffersApi.reducer,
	[profitIndiaOffersApi.reducerPath]: profitIndiaOffersApi.reducer,
	[profitKeniaOffersApi.reducerPath]: profitKeniaOffersApi.reducer,
	[profitSouthAfricaOffersApi.reducerPath]: profitSouthAfricaOffersApi.reducer,
	[profitSriLankaOffersApi.reducerPath]: profitSriLankaOffersApi.reducer,
	[profitUkraineOffersApi.reducerPath]: profitUkraineOffersApi.reducer,
	[openStatOffersApi.reducerPath]: openStatOffersApi.reducer,
	[top10OffersApi.reducerPath]: top10OffersApi.reducer
};

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware()
		.concat(profitOffersApi.middleware)
		.concat(profitPhilippinesOffersApi.middleware)
		.concat(profitVietnamOffersApi.middleware)
		.concat(profitUnitedStatesOffersApi.middleware)
		.concat(profitRomaniaOffersApi.middleware)
		.concat(profitSpainOffersApi.middleware)
		.concat(profitMexicoOffersApi.middleware)
		.concat(profitPolandOffersApi.middleware)
		.concat(profitNigeriaOffersApi.middleware)
		.concat(profitColumbiaOffersApi.middleware)
		.concat(profitCzechiaOffersApi.middleware)
		.concat(profitIndiaOffersApi.middleware)
		.concat(profitKazakhstanOffersApi.middleware)
		.concat(profitKeniaOffersApi.middleware)
		.concat(profitSouthAfricaOffersApi.middleware)
		.concat(profitSriLankaOffersApi.middleware)
		.concat(profitUkraineOffersApi.middleware)
		.concat(openStatOffersApi.middleware)
		.concat(top10OffersApi.middleware)
});