import React from 'react';
import { useSelector } from "react-redux";
import { useModalContext } from "utils/customHooks/ModalContext";
import { Translate } from "utils/translations/Translate";

import styles from './OfferCardLanding.module.scss'
import sphere from "assets/images/public/offers/sphere.png";

function OfferCardLanding({ offer }) {
	const { showRegLogModal } = useModalContext();
	const { lang } = useSelector(state => state.translations);
	const MAX_CHARACTERS = 350;

	// initialize translate class
	const translation = new Translate('offerCard', lang)
	const t = translation.getWord()

	// show registration when user click on offer
	const handleRegLogModal = () => {
		showRegLogModal('registration')
	}

	const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
          return text;
        } else {
          return text.substring(0, maxLength) + '...';
        }
    };
    const truncatedDescription = offer && offer.details && typeof offer.details.mainPageDesc === 'string' ? truncateText(offer.details.mainPageDesc, MAX_CHARACTERS) : '';

	return (
		<div className={styles.cards__item}>
			<div className={`${styles.cards__left}`}>
				<div className={styles.cards__header}>
					<img src={offer.details.image} alt={offer.details.name} />
				</div>
				<div className={`${styles.cards__stat}`}>
					{offer.details?.openStatLeadTypes?.map(stat => (
						<div key={stat.id}>
							<span className={styles.cards__stat_name}>{stat.name}</span>
							<span className={styles.cards__stat_value}>{stat.webPrice} <span>{stat.currency}</span></span>
						</div>
					))}
				</div>
				<div className={`${styles.cards__info} d-flex`}>
					<div className='d-flex align-items-end'>
						<img src={sphere} alt="sphere" />
						<span>{offer.details.category}</span>
					</div>
					<div className='d-flex align-items-center'>
						<img src={`assets/images/flags/4x3/${offer.details.country.toLowerCase()}.svg`} alt="flag" />
						<span>{offer.details.country}</span>
					</div>
				</div>
			</div>

			<div className={`${styles.cards__right}`}>
				<p className={styles.cards__description}>{truncatedDescription}</p>
				<button className={`${styles.cards__more} d-flex align-items-end`} onClick={handleRegLogModal} >
					{t('Start now')}
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M12.4382 6.48938L4 14.9276L5.76052 16.6881L14.1987 8.2499L14.1987 15.8078L16.6881 15.8078L16.6881 4L4.88026 4L4.88026 6.48938H12.4382Z"
							fill="#7CFF03" />
					</svg>
				</button>
			</div>
		</div>
	);
}

export default OfferCardLanding;