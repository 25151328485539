import { lazy, Suspense } from 'react';
import { 
	ADVERTISERS_PATH, 
	ERROR_PAGE_404_PATH, 
	FAQ_PATH, MAIN_PATH, 
	OFFERS_PATH, 
	RULES_PATH
} from "./path";
import { Loader } from "components/general";

const MainPage = lazy(() => import('pages/public/Main/Main'))
const Offers = lazy(() => import('pages/public/Offers/Offers'))
const Advertisers = lazy(() => import('pages/public/Advertisers/Advertisers'))
const FAQ = lazy(() => import('pages/public/FAQ/FAQ'))
const Rules = lazy(() => import('pages/public/Rules/Rules'))
const NotFound = lazy(() => import('pages/NotFound'))

const MAIN = {
	element: (
		<Suspense fallback={<Loader />}>
			<MainPage />
		</Suspense>
	),
	path: MAIN_PATH,
};

const OFFERS = {
	element: (
		<Suspense fallback={<Loader />}>
			<Offers />
		</Suspense>
	),
	path: OFFERS_PATH,
};

const ADVERTISERS = {
	element: (
		<Suspense fallback={<Loader />}>
			<Advertisers />
		</Suspense>
	),
	path: ADVERTISERS_PATH,
};

const FAQ_PAGE = {
	element: (
		<Suspense fallback={<Loader />}>
			<FAQ />
		</Suspense>
	),
	path: FAQ_PATH,
};

const RULES = {
	element: (
		<Suspense fallback={<Loader />}>
			<Rules />
		</Suspense>
	),
	path: RULES_PATH,
};

const NOT_FOUND = {
	element: (
		<Suspense fallback={<Loader />}>
			<NotFound />
		</Suspense>
	),
	path: ERROR_PAGE_404_PATH,
};

const routes = [
	MAIN,
	OFFERS,
	ADVERTISERS,
	FAQ_PAGE,
	RULES,
	NOT_FOUND
];

export default routes;