import React, { useState, useEffect } from 'react';

import './SubscribeModal.scss';

import imgEllipseWidget from '../../../assets/images/public/subscribe_modal/ellipse_widget.png';
import imgPercentWidget from '../../../assets/images/public/subscribe_modal/percent_widget.svg';

const SubscribeModal = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [alreadySubscribed, setAlreadySubscribed] = useState(false);
  const [almostSubscribed, setAlmostSubscribed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [bodyOverflow, setBodyOverflow] = useState('auto'); 

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalVisible(true);
      setBodyOverflow('hidden');
    }, 15000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    return () => {
      setBodyOverflow('auto');
    };
  }, []);

  const showPopup = () => {
    setModalVisible(true);
    setBodyOverflow('hidden');
  };

  const closeModal = () => {
    setModalVisible(false);
    setBodyOverflow('auto');
  };



  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setNameError(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.length < 2 || !name.match(/^[a-zA-ZА-Яа-я ]+$/)) {
      setNameError(true);
    }

    if (email === '' || !email.match(/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/)) {
      setEmailError(true);
    }

    if (name.length > 1 && name.match(/^[a-zA-ZА-Яа-я ]+$/) && email !== '' && email.match(/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/)) {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      console.log('submit')

      fetch(`${window.location.origin}/serviceapi/selzy/add`,{
        method: 'POST',
        body: formData,
      })
      .then((res) => res.json())
      .then((response) => {
        if (response.message === 'Email already exists') {
          setAlreadySubscribed(true);
        } else if (response.message === 'Email has been subscribed successfully') {
          setAlmostSubscribed(true);
        } else {
          setErrorMessage(response.message);
        }

        localStorage.setItem('contactInfo', '1');
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
};


  return (
    <>
      <style>
        {`
          body {
            overflow: ${bodyOverflow};
          }
        `}
      </style>

      <button onClick={showPopup} type="button" className="subscribe__modal--btn" data-toggle="modal" data-target="#subscribeModal">
        <img src={imgEllipseWidget} alt="ellipse" />
        <img className="percent" src={imgPercentWidget} alt="percent" />
        <img className="percent" src={imgPercentWidget} alt="percent" />
        <img className="percent" src={imgPercentWidget} alt="percent" />
      </button>

      
        <div 
            className={`modal fade${modalVisible ? ' show' : ''}`} 
            id="subscribeModal" 
            tabIndex="-1" 
            aria-labelledby="subscribeModalLabel" 
            aria-hidden={!modalVisible}
            onClick={handleOverlayClick}
        >
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.11111 3.33334C2.49746 2.71969 2.49746 1.72477 3.11111 1.11112V1.11112C3.72475 0.497474 4.71967 0.497474 5.33332 1.11112L20.8888 16.6666C21.5025 17.2803 21.5025 18.2752 20.8888 18.8888V18.8888C20.2752 19.5025 19.2803 19.5025 18.6666 18.8888L3.11111 3.33334Z" fill="#F8FFE4"></path>
                      <path d="M18.6667 1.1112C19.2804 0.497552 20.2753 0.497551 20.8889 1.1112C21.5026 1.72485 21.5026 2.71977 20.8889 3.33341L5.33344 18.8889C4.71979 19.5026 3.72487 19.5026 3.11122 18.8889C2.49758 18.2753 2.49758 17.2803 3.11122 16.6667L18.6667 1.1112Z" fill="#F8FFE4"></path>
                  </svg>
                </button>
              </div>
              <div className="modal-body">
              {alreadySubscribed ? (
                <AlreadySubscribedLayout />
                    ) : almostSubscribed ? (
                      <AlmostSubscribedLayout />
                    ) : (
                      <>
                        <h2 className="subscribe__title">
                          <span className="green">+5% к профиту</span> за подписку!
                        </h2>
                        <p className="subscribe__text">Получи бонус и зарабатывай больше на PDL офферах.</p>
                        <form >
                          <div className="input__block">
                            <input type="text" name="name" placeholder="Имя *" value={name} onChange={handleNameChange} style={nameError ? { backgroundColor: '#ffb9b9' } : {}} />
                            <input type="email" name="email" placeholder="E-mail *" value={email} onChange={handleEmailChange} style={emailError ? { backgroundColor: '#ffb9b9' } : {}} />
                          </div>

                          <p className="subscribe__more">А еще подписчикам:</p>
                          <ul className="subscribe__list">
                            <li>индивидуальные ставки</li>
                            <li>персональные акции</li>
                            <li>полезные советы и актуальные новости</li>
                          </ul>

                          <button type="submit" className="submit__subscription" onClick={handleSubmit}>Подписаться</button>
                        </form>
                      </>
                    )}
                    {errorMessage && <p className="error mb-2 text-center">{errorMessage}</p>}
              </div>
            </div>
          </div>
        </div>

    </>
  );


};
const AlreadySubscribedLayout = () => (
    <>
      <h2 class="subscribe__title subscribed">
				ты <span class="green">уже получаешь</span> наши письма!
			</h2>
			<p class="subscribe__text subscribed">Возможно, раньше они терялись, проверь папки «Спам» и «Промоакции».</p>
    </>
);

const AlmostSubscribedLayout = () => (
    <>
      <h2 class="subscribe__title subscribed">
				остался <span class="green">последний шаг</span> 
			</h2>
			<p class="subscribe__text subscribed">Найди письмо от нас на почте, подтверди подписку и лови бонус!</p>
    </>
);

export default SubscribeModal;
