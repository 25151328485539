import React from 'react';
import { useSelector } from "react-redux";

import { Translate } from "utils/translations/Translate";
import { useModalContext } from "utils/customHooks/ModalContext";

import { OffersChart } from "../index";
import { Accordion } from "react-bootstrap";

import styles from './OfferItem.module.scss'
import sphere from "assets/images/public/offers/sphere.png";


function OfferItem({ offer }) {
	const { showRegLogModal } = useModalContext();
	const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('offerCard', lang)
	const t = translation.getWord()

	// show registration when user click on offer
	const handleRegLogModal = () => {
		showRegLogModal('registration')
	}

	return (
		<div className={styles.offers__item}>
			<div className={`d-flex flex-column`}>
				<div className={styles.info__logo}>
					<img src={offer.details?.image} alt={offer.details?.name} />
				</div>
				<div className={`${styles.info__type} d-flex flex-row flex-md-column flex-xl-row`}>
					<div className='d-flex align-items-center'>
						<img src={sphere} alt="sphere" />
						<span>{offer.details?.category}</span>
					</div>
					<div className='d-flex align-items-center'>
						<img src={`assets/images/flags/4x3/${offer.details?.country.toLowerCase()}.svg`} alt="flag" />
						<span>{offer.details?.country}</span>
					</div>
				</div>
			</div>
			<div className={`${styles.stats} d-flex flex-column justify-content-between`}>
				<div className={styles.stats__name}>
					{offer.details?.name}
				</div>

				{/* show accordion with stats only if it is enabled in response */}
				{offer.details?.openStatLeadTypes && offer.details?.openStatLeadTypes.length > 0 && (
					<Accordion className={styles.stats__wrap}>
						<Accordion.Item eventKey="0">
							<Accordion.Header>
								<div className={styles.stats__item}>
									<div className={styles.stats__title}>
										{offer.details?.openStatLeadTypes[0]?.name}
									</div>
									<div className={styles.stats__nums}>
										<span>{offer.details?.openStatLeadTypes[0]?.webPrice}</span> {offer.details?.openStatLeadTypes[0]?.currency}
									</div>
								</div>
								{offer.details?.openStatLeadTypes.length > 1 && (
									<div className={styles.stats__item}>
										<div className={styles.stats__title}>
											{offer.details?.openStatLeadTypes[1]?.name}
										</div>
										<div className={styles.stats__nums}>
											<span>{offer.details?.openStatLeadTypes[1]?.webPrice}</span> {offer.details?.openStatLeadTypes[1]?.currency}
										</div>
									</div>
								)}

								{offer.details?.openStatLeadTypes.length > 2 && <span className='accordion__arrow' />}

							</Accordion.Header>
							{offer.details?.openStatLeadTypes.length > 2 && (
								<Accordion.Body>
									{offer.details?.openStatLeadTypes.slice(2).map(item => {
										return <div key={item.id} className={styles.stats__item}>
											<div className={styles.stats__title}>
												{item.name}
											</div>
											<div className={styles.stats__nums}>
												<span>{item.webPrice}</span> {item.currency}
											</div>
										</div>
									})}
								</Accordion.Body>
							)}

						</Accordion.Item>
					</Accordion>
				)}


			</div>

			<div className={styles.chart}>
				<OffersChart stats={offer.stats} />
			</div>

			<div className={`${styles.average} d-flex d-xl-block flex-column justify-content-between`}>
				<div className={styles.average__wrapper}>
					<div className={styles.average__item}>
						<div className={styles.average__name}>
							EPC:
						</div>
						<div className={styles.average__count}>
							{offer.details?.ecpc}

							{/* what arrows to show up or down */}
							{offer.details?.ecpcTrend === 'up' && (
								<svg width="21" height="14" viewBox="0 0 21 14" fill="none"
									 xmlns="http://www.w3.org/2000/svg">
									<path
										d="M1 13L8.01538 6.14286L11.5231 9.57143L20 1M20 1V6.71429M20 1H14.1538"
										stroke="#7CFF03" strokeWidth="1.4" strokeLinecap="round" />
								</svg>
							)}
							{offer.details?.ecpcTrend === 'down' && (
								<svg width="21" height="14" viewBox="0 0 21 14" fill="none"
									 xmlns="http://www.w3.org/2000/svg">
									<path
										d="M1 1L8.01538 7.85714L11.5231 4.42857L20 13M20 13V7.28571M20 13H14.1538"
										stroke="#FF3737" strokeWidth="1.4" strokeLinecap="round" />
								</svg>
							)}
						</div>
					</div>
					<div className={styles.average__item}>
						<div className={styles.average__name}>
							CR:
						</div>
						<div className={styles.average__count}>
							{offer.details?.cr}

							{/* what arrows to show up or down */}
							{offer.details?.crTrend === 'up' && (
								<svg width="21" height="14" viewBox="0 0 21 14" fill="none"
									 xmlns="http://www.w3.org/2000/svg">
									<path
										d="M1 13L8.01538 6.14286L11.5231 9.57143L20 1M20 1V6.71429M20 1H14.1538"
										stroke="#7CFF03" strokeWidth="1.4" strokeLinecap="round" />
								</svg>
							)}
							{offer.details?.crTrend === 'down' && (
								<svg width="21" height="14" viewBox="0 0 21 14" fill="none"
									 xmlns="http://www.w3.org/2000/svg">
									<path
										d="M1 1L8.01538 7.85714L11.5231 4.42857L20 13M20 13V7.28571M20 13H14.1538"
										stroke="#FF3737" strokeWidth="1.4" strokeLinecap="round" />
								</svg>
							)}
						</div>
					</div>
				</div>
				<button className={styles.connect} onClick={handleRegLogModal}>
					{t('подключить')}
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none"
						 xmlns="http://www.w3.org/2000/svg">
						<path
							d="M12.4382 6.48938L4 14.9276L5.76052 16.6881L14.1987 8.2499L14.1987 15.8078L16.6881 15.8078L16.6881 4L4.88026 4L4.88026 6.48938H12.4382Z"
							fill="#7CFF03" />
					</svg>
				</button>
			</div>
		</div>
	);
}

export default OfferItem;