import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	currentPage: 1,
	totalPages: null,
	region: { name: 'Любой регион', value: '' },
	orderBy: 'EPC',
	orderDir: 'DESC',
	loading: false
}


export const offersSlice = createSlice({
	name: 'offers',
	initialState,
	reducers: {
		setCurrentPage(state, action) {
			state.currentPage = Number(action.payload)
		},
		setTotalPages(state, action) {
			state.totalPages = Number(action.payload)
		},
		setRegion(state, action) {
			state.region = action.payload
		},
		setParams(state, action) {
			state.currentPage = Number(action.payload.page)
			state.region = action.payload.region
			state.orderBy = action.payload.orderBy
			state.orderDir = action.payload.orderDir
		},
		setOrderBy(state, action) {
			state.orderBy = action.payload
		},
		setOrderDir(state, action) {
			state.orderDir = action.payload
		}
	},
})

export const {
	setCurrentPage,
	setTotalPages,
	setRegion,
	setParams,
	setOrderDir,
	setOrderBy
} = offersSlice.actions