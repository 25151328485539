import React from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Translate } from "utils/translations/Translate";

import SubscribeModal from '../SubscribeModal/SubscribeModal';

import styles from './FooterPublic.module.scss'
import logo from 'assets/images/general/logo-pdl.svg'


function FooterPublic() {
	const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('footerFactory', lang)
	const t = translation.getWord()

	return (
		<>
		<footer className={styles.footer}>
			<div className="container">
				<div className={styles.footer__wrapper}>
					<div className={styles.info}>
						<div className={styles.info__logo}>
							<img src={logo} alt="logo"/>
						</div>
						<h3 className={styles.info__title}>
							{t('Международная CPA сеть')}
							<br/>
							{t('в финансовой вертикали PDL-profit')}
						</h3>
					</div>
					<ul className={styles.menu}>
						<li className={styles.menu__item}>
							<Link to='/'>{t('О нас')}</Link>
						</li>
						<li className={styles.menu__item}>
							<Link to='/'>{t('Вебмастеру')}</Link>
						</li>
						<li className={styles.menu__item}>
							<Link to='https://pdl-profit.com/advertiser.html'>{t('Рекламодателю')}</Link>
						</li>
						<li className={styles.menu__item}>
							<Link to='/our-offers.html'>{t('Офферы')}</Link>
						</li>
						<li className={styles.menu__item}>
							<Link to='/rules.html'>{t('Правила')}</Link>
						</li>
						<li className={styles.menu__item}>
							<Link to='/faq.html'>FAQ</Link>
						</li>
					</ul>
				</div>
				<p className={styles.footer__text}>
					ТОО «ПДЛ-Профит». БИН 990841000632.<br/>Юридический
					адрес: Казахстан, город Алматы, Медеуский район, Проспект Достык, дом 128, н.п. 183,
					почтовый индекс 050051. Почтовый адрес: Казахстан, город Алматы, Медеуский район, Проспект
					Достык, дом 128, н.п. 183, почтовый индекс 050051. Телефон/факс: +7 708 818 00 18.
					Банковские реквизиты: KZ8896502F0015439499<br/> Все права защищены.
				</p>
			</div>
		</footer>
			<SubscribeModal/>
		</>
	);
}

export default FooterPublic;