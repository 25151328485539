import React from 'react';
import { useSelector } from "react-redux";
import { Translate } from "utils/translations/Translate";

import styles from './RegBlock.module.scss'

const REGISTRATION_URL = process.env.REACT_APP_REGISTRATION_URL
const LOGIN_URL = process.env.REACT_APP_LOGIN_URL

function RegBlock({ display, showRegLogModal }) {
	const { lang } = useSelector(state => state.translations)

	// initialize translate class
	const translation = new Translate('public/auth', lang)
	const t = translation.getWord()

	return (
		<div className={`${styles.reg_block} ${display}`}>
			<span className={`d-flex align-items-center ${styles.reg}`}
				  onClick={() => window.location.href = REGISTRATION_URL}>
				<svg width="20" height="21" viewBox="0 0 20 21" fill="none"
					 xmlns="http://www.w3.org/2000/svg">
					<path
						d="M9.99999 10.5001C12.3012 10.5001 14.1667 8.6346 14.1667 6.33341C14.1667 4.03223 12.3012 2.16675 9.99999 2.16675C7.69881 2.16675 5.83333 4.03223 5.83333 6.33341C5.83333 8.6346 7.69881 10.5001 9.99999 10.5001Z"
						stroke="#F8FFE4" strokeWidth="1.4" />
					<path
						d="M14.1667 12.1667H14.46C15.0692 12.1669 15.6574 12.3896 16.1141 12.7928C16.5708 13.1961 16.8645 13.7522 16.94 14.3567L17.2658 16.9601C17.2951 17.1946 17.2742 17.4327 17.2045 17.6585C17.1348 17.8843 17.0178 18.0927 16.8614 18.2699C16.7049 18.4471 16.5126 18.5889 16.2971 18.6861C16.0817 18.7833 15.848 18.8335 15.6117 18.8334H4.38833C4.15198 18.8335 3.91832 18.7833 3.70287 18.6861C3.48741 18.5889 3.29508 18.4471 3.13864 18.2699C2.9822 18.0927 2.86523 17.8843 2.7955 17.6585C2.72576 17.4327 2.70485 17.1946 2.73416 16.9601L3.05916 14.3567C3.13474 13.7519 3.42867 13.1956 3.88569 12.7923C4.3427 12.389 4.93131 12.1665 5.54083 12.1667H5.83333"
						stroke="#F8FFE4" strokeWidth="1.4" strokeLinecap="round"
						strokeLinejoin="round" />
				</svg>
				{t('Регистрация')}
			</span>
			<span className={`d-flex align-items-center outline-grey ${styles.enter}`}
				  onClick={() => window.location.href = LOGIN_URL}>
				<svg width="20" height="20" viewBox="0 0 20 20" fill="none"
					 xmlns="http://www.w3.org/2000/svg">
					<path
						d="M7.75 17.5C7.55109 17.5 7.36032 17.421 7.21967 17.2803C7.07902 17.1397 7 16.9489 7 16.75C7 16.5511 7.07902 16.3603 7.21967 16.2197C7.36032 16.079 7.55109 16 7.75 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V6C16 5.46957 15.7893 4.96086 15.4142 4.58579C15.0391 4.21071 14.5304 4 14 4H7.75C7.55109 4 7.36032 3.92098 7.21967 3.78033C7.07902 3.63968 7 3.44891 7 3.25C7 3.05109 7.07902 2.86032 7.21967 2.71967C7.36032 2.57902 7.55109 2.5 7.75 2.5H14C14.9283 2.5 15.8185 2.86875 16.4749 3.52513C17.1313 4.1815 17.5 5.07174 17.5 6V14C17.5 14.9283 17.1313 15.8185 16.4749 16.4749C15.8185 17.1313 14.9283 17.5 14 17.5H7.75ZM7.741 6.199C7.81335 6.13214 7.89816 6.08019 7.9906 6.04612C8.08303 6.01205 8.18127 5.99652 8.2797 6.00042C8.37813 6.00432 8.47484 6.02757 8.56428 6.06885C8.65373 6.11013 8.73417 6.16863 8.801 6.241L11.801 9.491C11.929 9.62957 12.0002 9.81132 12.0002 10C12.0002 10.1887 11.929 10.3704 11.801 10.509L8.801 13.759C8.73409 13.8313 8.6536 13.8897 8.56412 13.9309C8.47464 13.9721 8.37792 13.9952 8.27949 13.999C8.18106 14.0028 8.08284 13.9872 7.99045 13.9531C7.89806 13.9189 7.81329 13.8669 7.741 13.8C7.66871 13.7331 7.6103 13.6526 7.56912 13.5631C7.52793 13.4736 7.50477 13.3769 7.50097 13.2785C7.49328 13.0797 7.56487 12.886 7.7 12.74L9.538 10.749H1.75C1.55109 10.749 1.36032 10.67 1.21967 10.5293C1.07902 10.3887 1 10.1979 1 9.999C1 9.80009 1.07902 9.60932 1.21967 9.46867C1.36032 9.32802 1.55109 9.249 1.75 9.249H9.537L7.699 7.259C7.63214 7.18665 7.58019 7.10184 7.54612 7.0094C7.51205 6.91697 7.49652 6.81873 7.50042 6.7203C7.50432 6.62187 7.52757 6.52516 7.56885 6.43572C7.61013 6.34627 7.66863 6.26583 7.741 6.199Z"
						fill="#F8FFE4" />
				</svg>
				{t('Вход')}
			</span>
		</div>
	);
}

export default RegBlock;