import React from 'react';
import { FooterPublic, FooterLanding } from "../index";



const FooterFactory = ({ isAuth, isLandingPage }) => {

    return <FooterPublic />;
	
};

export default FooterFactory;